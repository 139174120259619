/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em",
    strong: "strong",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    h3: "h3",
    ul: "ul",
    li: "li",
    ol: "ol"
  }, _provideComponents(), props.components), {Warning, Specialization, Skill, Condition, Item, Trait, Boon, Divider, Grid, GridItem, Traits, Card, Skills, Instability, Effect, Video} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!GridItem) _missingMdxReference("GridItem", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Skills) _missingMdxReference("Skills", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  if (!Video) _missingMdxReference("Video", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Warning, null, React.createElement(_components.p, null, "This build is strong in full condi groups (", React.createElement(_components.a, {
    href: "/guides/effective-comp"
  }, "team comps"), "). In terms of DPS, it is unlikely to fall behind any other specialization, whether they be running power or condi build, as it has very high sustained DPS on single targets."), React.createElement(_components.p, null, "Lastly, a condi build will always tend to interfere with a power composition, as it is not meant to have a higher upfront or burst damage, but both of these builds will do a lot of burst damage as well. It is worth mentioning that ", React.createElement(_components.em, null, "Venom Skills"), " are very strong in that regard, and precasting them on the ", React.createElement(_components.em, null, "Mistlock Singularity"), " will allow for a very high burst, quite uncommon for condi builds but very valuable.")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Condi Deadeye",
    name: "Deadeye"
  })), " is quite a selfish build, that will not provide your party with any form of support. Instead, the value of this build comes almost entirely from the pure damage that it will bring, which is inflated by the ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Condi Soulbeast",
    name: "Soulbeast"
  })), "'s ", React.createElement(Skill, {
    id: "40498",
    id: "40498"
  }), ", on top of Venom Skills which can be precast on the ", React.createElement(_components.em, null, "Mistlock Singularity"), ", and cast again as soon as your allies have consumed them by attacking the target. Consequently, the ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Condi Deadeye",
    name: "Deadeye"
  })), " is able to apply a tremendous amount of ", React.createElement(Condition, {
    name: "Poisoned"
  }), " and ", React.createElement(Condition, {
    name: "Bleeding"
  }), " instances, as well as an non-negligible amount of ", React.createElement(Condition, {
    name: "Torment"
  }), ". As such, it is necessary to be running with a ", React.createElement(Item, {
    id: "44944",
    id: "44944"
  }), " as it will become very valuable in bursting phases. Lastly, ", React.createElement(Skill, {
    id: "13132",
    id: "13132"
  }), " can bring up to 750 Defiance Bar damage, if used at the proper moment, meaning if all instances of this ability are consumed when the boss is vulnerable to this type of damage (up to five stacks, one per player)."), "\n", React.createElement(_components.p, null, "This build possesses a decent amount of self-sustain, due to ", React.createElement(Trait, {
    id: "2111",
    id: "2111"
  }), ", but it will suffer greatly from a ", React.createElement(Boon, {
    name: "Quickness"
  }), " deficit. However, it won't suffer too much from a lack of ", React.createElement(Boon, {
    name: "Alacrity"
  }), " due to the nature of ", React.createElement(_components.em, null, "Initiative"), "."), "\n", React.createElement(_components.p, null, "Overall, this build is an excellent pick if taken as an alt class: it is meant for players who enjoy high-risk, high-reward rotations. Played correctly, it is excellent in PuGs on single-target bosses such as the ones from Challenge Mode Fractals, most notably Sorrowful Spellcaster (Light Ai) and Ensolyss, or any boss with little to no phases, as well as multiple add phases, such as the ones from Shattered Observatory.\nA playlist of videos showcasing the ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Condi Deadeye",
    name: "Deadeye"
  })), " and the ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Condi Daredevil",
    name: "Daredevil"
  })), " can be found on ", React.createElement(_components.a, {
    href: "https://www.youtube.com/playlist?list=PLC8zIP7qMiNPQAHWjt_V_B_EtGaJaGJaM"
  }, "Magic's channel"), ". It is currently incomplete, but you will find benchmarks, CM boss kills, and some T4 gameplay in organized groups."), "\n", React.createElement(_components.p, null, "We do not recommend running these builds in Shattered Observatory for new players or beginners, as positioning is most important there, and jumping ", React.createElement(Skill, {
    name: "shadowstrike",
    id: "13010"
  }), " becomes an issue due to Cosmic Energy (low gravity). Extensive details for the build can be found at the bottom of the page."), "\n", React.createElement(Divider, {
    text: "Equipment"
  }), "\n", React.createElement(_components.p, null, "Note that there are two slightly different variants of this build: one is meant for bosses with very short phases such as Ensolyss or Light Ai; the other one is meant for longer fights, where you will need to sustain damage for a longer period. This will also depend on your group. It will always be better to use the latter with people you do not know well, or in situations where your party doesn't bring enough damage.\nThe ", React.createElement(Item, {
    name: "afflicted",
    id: "24687"
  }), " is a viable, more accessible alternative.\nIf healing isn't an issue, this is the perfect build to use ", React.createElement(Item, {
    name: "writofmasterfulmalice",
    id: "72510"
  }), " over ", React.createElement(Item, {
    name: "tuningicicle",
    id: "77567"
  }), " ! ", React.createElement(Skill, {
    name: "signetofmalice",
    id: "13050"
  }), " is also the strongest personal-healing ability you can bring to maintain your health over 90%, if you feel you don't need ", React.createElement(Skill, {
    name: "hideinshadows",
    id: "13027"
  }), "."), "\n", React.createElement(_components.p, null, "The ", React.createElement(Skill, {
    name: "skelkvenom",
    id: "21778"
  }), " is a much better option to support your party as it brings a tremendous amount of party healing.\nIt's recommended to run a shortbow for additional movement during downtime and ", React.createElement(Item, {
    name: "doom",
    id: "24609"
  }), " precasts, as well as ", React.createElement(Boon, {
    name: "might"
  }), " blasts on the ", React.createElement(_components.em, null, "Mistlock Singularity"), ". You will also want a Pistol with ", React.createElement(Item, {
    id: "24639",
    id: "24639"
  }), " to help CC during Artsariiv splits."), "\n", React.createElement(Divider, {
    text: "Build"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1: "Trickery",
    traits1Selected: "Burst of Agility,Pressure Striking,Deadly Ambush",
    traits2: "Deadly Arts",
    traits2Selected: "Deadly Ambition,Panic Strike,Potent Poison",
    traits3: "Deadeye",
    traits3Selected: "One in the Chamber,Payback,Maleficent Seven",
    traits1Id: "44",
    traits1SelectedIds: "1252,1190,1706",
    traits2Id: "28",
    traits2SelectedIds: "1164,1292,1291",
    traits3Id: "58",
    traits3SelectedIds: "2136,2078,2111"
  })), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Card, {
    title: "Situational Skills"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "13026",
    size: "big",
    disableText: true,
    id: "13026"
  })), React.createElement(_components.td, null, "A situational damage ability, to take over ", React.createElement(Skill, {
    name: "devourervenom",
    id: "13093"
  }), " on stationary targets.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "infiltratorssignet",
    size: "big",
    disableText: true,
    id: "13064"
  })), React.createElement(_components.td, null, "A quick shadowstep. Beginner-friendly alternative, which can be used for a bit more survivability.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "signetofmalice",
    size: "big",
    disableText: true,
    id: "13050"
  })), React.createElement(_components.td, null, "Highest sustained healing.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "skelkvenom",
    size: "big",
    disableText: true,
    id: "21778"
  })), React.createElement(_components.td, null, "Best party healing."))))), React.createElement(Card, {
    title: "Defiance Bar Damage"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "13132",
    id: "13132"
  })), React.createElement(_components.td, null, "up to 750 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Headshot",
    id: "13012"
  })), React.createElement(_components.td, null, "200 damage (260 with ", React.createElement(Item, {
    id: "24639",
    id: "24639"
  }), ")"))))))), "\n", React.createElement(Divider, {
    text: "Build Variants"
  }), "\n", React.createElement(_components.h3, null, "High-Burst Variant"), "\n", React.createElement(_components.p, null, "This variant is meant for short-duration fights or bosses with short phases; without any Cantrip slotted, take ", React.createElement(Trait, {
    name: "maliciousintent",
    id: "2145"
  }), " over ", React.createElement(Trait, {
    name: "oneinthechamber",
    id: "2136"
  }), ". Take ", React.createElement(Skill, {
    id: "13026",
    id: "13026"
  }), " over ", React.createElement(Skill, {
    name: "devourervenom",
    id: "13093"
  }), " on Ensolyss, Skorvald or Artsariiv, or ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), " with ", React.createElement(Trait, {
    name: "oneinthechamber",
    id: "2136"
  }), " if group DPS is not high enough not to run out of ", React.createElement(_components.em, null, "Initiative"), ". ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), " can still be taken over ", React.createElement(Skill, {
    name: "devourervenom",
    id: "13093"
  }), " as a safety net. ", React.createElement(Skill, {
    name: "hideinshadows",
    id: "13027"
  }), " becomes a necessity because of the lack of stealth applications."), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "4"
  }, React.createElement(Skills, {
    heal: "Hide in Shadows",
    utility1: "Devourer Venom",
    utility2: "Skale Venom",
    utility3: "Spider Venom",
    elite: "Basilisk Venom",
    unembossed: true,
    healId: "13027",
    utility1Id: "13093",
    utility2Id: "13055",
    utility3Id: "13037",
    eliteId: "13132"
  })), React.createElement(GridItem, {
    sm: "8"
  }, React.createElement(Traits, {
    traits1: "Deadeye",
    traits1Selected: "Malicious Intent,Payback,Maleficent Seven",
    unembossed: true,
    traits1Id: "58",
    traits1SelectedIds: "2145,2078,2111"
  }))), "\n", React.createElement(_components.h3, null, "No Pain, No Gain Variant"), "\n", React.createElement(_components.p, null, React.createElement(Trait, {
    id: "1277",
    id: "1277"
  }), " over ", React.createElement(Trait, {
    id: "1190",
    id: "1190"
  }), " on ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " days, or for some more support."), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "4"
  }, React.createElement(Skills, {
    heal: "Hide in Shadows",
    utility1: "Mercy",
    utility2: "Skale Venom",
    utility3: "Spider Venom",
    elite: "Shadow Meld",
    unembossed: true,
    healId: "13027",
    utility1Id: "41372",
    utility2Id: "13055",
    utility3Id: "13037",
    eliteId: "45508"
  })), React.createElement(GridItem, {
    sm: "8"
  }, React.createElement(Traits, {
    traits1: "Trickery",
    traits1Selected: "Burst of Agility,Bountiful Theft,Deadly Ambush",
    unembossed: true,
    traits1Id: "44",
    traits1SelectedIds: "1252,1277,1706"
  }))), "\n", React.createElement(Divider, {
    text: "Details"
  }), "\n", React.createElement(_components.p, null, "Watching videos of players performing the rotation for this build, one might think that it is easy to play; after all, the ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Thief",
    name: "Thief"
  })), " tends to have spammy rotations, for the same reason mentioned before. The ", React.createElement(_components.em, null, "Initiative"), " system allows for multiple, redundant casts of the same ability, during the entire duration of the fight. It is easy to see that one ability will always perform better than other ones, based on the ratio of damage dealt over ", React.createElement(_components.em, null, "Initiative"), " points spent. Here are the main reasons why this rotation isn't as easy as it looks, as well as some tips to master the rotation, depending on the encounter."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Condi Deadeye",
    name: "Deadeye"
  })), " has three primary damaging abilities: ", React.createElement(Skill, {
    id: "50466",
    id: "50466"
  }), ", ", React.createElement(Skill, {
    id: "59526",
    id: "59526"
  }), ", and ", React.createElement(Skill, {
    id: "13010",
    id: "13010"
  }), ". Due to the nature of ", React.createElement(Skill, {
    id: "13010",
    id: "13010"
  }), " (which has to be cast to enable ", React.createElement(Skill, {
    id: "59526",
    id: "59526"
  }), "), this rotation becomes very tricky, as it forces your character to Shadowstep Backwards: This is because the intention behind designing the Pistol/Dagger setup was probably to make a kiting kit, and it was never really meant to be a Best in Slot DPS kit. One way to bypass this is to ", React.createElement(_components.strong, null, "jump"), " during, or immediately after casting ", React.createElement(Skill, {
    id: "13010",
    id: "13010"
  }), ". Not only is it an inconvenience for the rotation, but it also lowers your DPS slightly, as there will be a small, but significant delay where you will still be up in the air, unable to make your next move. It is necessary to jump ", React.createElement(Skill, {
    id: "13010",
    id: "13010"
  }), " sometimes, such as when Dark Ai pulses AoEs in a set ring pattern, so as not to lose too much health, and fall into Downstate far away from your friends."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "shadowstrike",
    id: "13010"
  }), " can see its range alleviated by positioning the camera at a steep angle, above your character, and running inside of the hitbox. The latter reduces the porting range to approximately 400 units while combining the two reduces the range to about 180 units (courtesy of ", React.createElement(_components.a, {
    href: "https://youtu.be/lYSjF4Q7zZA"
  }, "Darkz"), ")."), "\n", React.createElement(_components.li, null, "The rotation requires a good sense of timing, as do pretty much all ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Deadeye",
    name: "Deadeye"
  })), " builds, to maximize your DPS: timing some abilities incorrectly, such as ", React.createElement(Skill, {
    id: "41372",
    id: "41372"
  }), " (which will consume your Malice in exchange for some ", React.createElement(_components.em, null, "Initiative"), " points), can have dramatic consequences for the rest of the fight: you might end up being completely unable to use any ability whatsoever because you have run out of ", React.createElement(_components.em, null, "Initiative"), " points to spend. Not being able to use abilities keeps your Malice bar too low, and this chain reaction can leave you powerless to do anything until you can use ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), " or ", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), " again."), "\n", React.createElement(_components.li, null, "Missing an ability like ", React.createElement(Skill, {
    name: "shadowstrike",
    id: "13010"
  }), " or hitting nothing but thin air with ", React.createElement(Skill, {
    name: "repeater",
    id: "13111"
  }), ", both of which might happen from being out of range, will unnecessarily use up some of your ", React.createElement(_components.em, null, "Initiative"), ", and might delay your ", React.createElement(Trait, {
    id: "2111",
    id: "2111"
  }), " which could cause some issues for the rest of the fight, like not filling up your ", React.createElement(_components.em, null, "Initiative"), " bar at the proper moment."), "\n", React.createElement(_components.li, null, "It is important to learn the rotation by heart, if you do not fully understand the ", React.createElement(_components.em, null, "Malice"), " mechanic. abilities such as ", React.createElement(Skill, {
    id: "50466",
    id: "50466"
  }), " are only enabled when you are under the effect of ", React.createElement(Effect, {
    name: "Stealth"
  }), "; notably, the latter has additional effects when Malice is consumed, so it is important ", React.createElement(_components.em, null, "not"), " to use ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), " before, or while casting it. Specifically, ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), " should always be used upon completing the ", React.createElement(Skill, {
    id: "50466",
    id: "50466"
  }), ", so as not to interfere with ", React.createElement(Effect, {
    name: "stealth"
  }), "-application skills like the random F2 Stolen skills (", React.createElement(Skill, {
    name: "stealtime",
    id: "42863"
  }), ", ...), ", React.createElement(Skill, {
    name: "hideinshadows",
    id: "13027"
  }), " or ", React.createElement(Skill, {
    name: "shadowmeld",
    id: "45508"
  }), "."), "\n", React.createElement(_components.li, null, "Stolen abilities (", React.createElement(Skill, {
    name: "stealhealth",
    id: "40903"
  }), ", ", React.createElement(Skill, {
    name: "stealstrength",
    id: "40904"
  }), "...) should ", React.createElement(_components.em, null, "not"), " be used if you have less than four ", React.createElement(_components.em, null, "Malice"), " points, as four of them ought to be consumed to provide you with ", React.createElement(Effect, {
    name: "Stealth"
  }), ", enabling ", React.createElement(Skill, {
    id: "50466",
    id: "50466"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    id: "2078",
    id: "2078"
  }), " is a key trait that will lower the cooldown of all of your Utility Skills by 20% of their total cooldown after killing a ", React.createElement(_components.em, null, "Marked"), " target. Targets can be ", React.createElement(_components.em, null, "Marked"), " with ", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), ". A good way to reset your ", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), " in order to reapply it to a new foe is to run ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), ". It is essential to run this trait on every CM boss, as it will allow you to precast additional ", React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "preparethousandneedles",
    id: "13026"
  })), " on Ensolyss, or more ", React.createElement(_components.em, null, "Venom Skills"), " on all of the other bosses. We also recommend ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), " on bosses such as MAMA, Siax, Skorvald, Artsariiv, and Arkk so as not to run out of initiative, and allow for more procs of ", React.createElement(Trait, {
    id: "2078",
    id: "2078"
  }), ". Note that ", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), " will always reset upon your ", React.createElement(_components.em, null, "Marked"), " target's death."), "\n", React.createElement(_components.li, null, "Lastly, it should be noted that ", React.createElement(Trait, {
    id: "2136",
    id: "2136"
  }), " is a crucial trait for longer fights, as the use of a cantrip like ", React.createElement(Skill, {
    name: "shadowmeld",
    id: "45508"
  }), " or ", React.createElement(Skill, {
    name: "Mercy",
    id: "41372"
  }), " will allow you to use a second Stolen ability, on top of the one that the ", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), " gives you every twenty seconds or so."), "\n"), "\n", React.createElement(Divider, {
    text: "Rotation / Skill usage"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "6"
  }, React.createElement(Card, {
    title: "Rotation"
  }, React.createElement(Warning, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Make sure to only press ", React.createElement(Skill, {
    name: "mercy",
    id: "41372"
  }), " ", React.createElement(_components.em, null, "after"), " completing ", React.createElement(Skill, {
    name: "malicioussneakattack",
    id: "50466"
  }), " !"))), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shadow Strike",
    id: "13010"
  }), " (Pistol 3) + ", React.createElement(Skill, {
    name: "Skale Venom",
    id: "13055"
  }), " + ", React.createElement(Skill, {
    name: "Spider Venom",
    id: "13037"
  })), "\n", React.createElement(_components.li, null, "Optional Immediate ", React.createElement(_components.strong, null, "Jump"), " after ", React.createElement(Skill, {
    name: "Shadow Strike",
    id: "13010"
  })), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Repeater",
    id: "13111"
  }), " Three times"), " (Pistol 3)"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "One Stealth Ability")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "malicioussneakattack",
    id: "50466"
  }), "\n"), "\n"), React.createElement(Warning, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Stealth abilities priority: ", React.createElement(Skill, {
    name: "stealtime",
    id: "42863"
  }), " > ", React.createElement(Skill, {
    name: "hideinshadows",
    id: "13027"
  }), " > ", React.createElement(Skill, {
    name: "shadowmeld",
    id: "45508"
  }), " > ", React.createElement(Skill, {
    name: "cloakanddagger",
    id: "16432"
  }), " (only if ", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), " is coming off cooldown)."))), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Repeat from 2."), " Use ", React.createElement(Skill, {
    name: "deadeyesmark",
    id: "43390"
  }), ", ", React.createElement(Skill, {
    name: "skalevenom",
    id: "13055"
  }), " and ", React.createElement(Skill, {
    name: "spidervenom",
    id: "13037"
  }), " whenever these abilities go off cooldown."))), React.createElement(GridItem, {
    sm: "6"
  }, React.createElement(Card, {
    title: "Golem rotation"
  }, React.createElement(Video, {
    youtube: "-Rk0Lu8QpbU",
    caption: "by Incera"
  })), React.createElement(Card, {
    title: "Precasting"
  }, React.createElement(_components.p, null, "All Damaging Venom Skills should be casted on the ", React.createElement(_components.em, null, "Mistlock Singularity"), ": ", React.createElement(Skill, {
    name: "Skale Venom",
    id: "13055"
  }), ", ", React.createElement(Skill, {
    name: "Spider Venom",
    id: "13037"
  }), " and ", React.createElement(Skill, {
    name: "Devourer Venom",
    id: "13093"
  }), ". On stationary bosses which can be manually activated, instead of precasting ", React.createElement(Skill, {
    name: "Devourer Venom",
    id: "13093"
  }), ", you can instead go to the spawn location and precast ", React.createElement(Skill, {
    name: "preparethousandneedles",
    id: "13026"
  }), ". All you have to do then is to activate the Preparation when the boss becomes vulnerable, and cast it again as it will be off cooldown. This is possible on Skorvald, Artsariiv, Arkk, MAMA, and Ensolyss with the use of a ", React.createElement(Item, {
    name: "White Mantle Portal Device",
    id: "78978"
  }), ", or on Siax without. It is also possible on the Sorrowful Spellcaster (Light & Dark Ai), but with a 1/4 chance of success."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "On Dark Ai, make sure to precast venoms on your ", React.createElement(Skill, {
    id: "13082",
    id: "13082"
  }), " minions, as every boon and special ability effect currently on you will be stripped upon starting the encounter."), "\n", React.createElement(_components.li, null, "Spam ", React.createElement(Skill, {
    name: "clusterbomb",
    id: "13041"
  }), " for ", React.createElement(Boon, {
    name: "might"
  }), " blasts on the ", React.createElement(_components.em, null, "Mistlock Singularity"), "."), "\n")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
